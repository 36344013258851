import request from '@/api/request.js';
// 获取收入概览
export function myIncome(data) {
    return request({
        url: 'business/api/finance/myIncome',
        method: 'post',
        data: data
    });
}
// 获取收入详情
export function incomeDetails(data) {
    return request({
        url: 'business/api/finance/incomeDetails',
        method: 'post',
        data: data
    });
}

// 银行账号列表

export function getBankAccountList(data) {
    return request({
        url: 'business/api/finance/getBankAccountList',
        method: 'get',
        params: data
    });
}

// 绑定银行账号

export function bindBankAccount(data) {
    return request({
        url: 'business/api/finance/bindBankAccount',
        method: 'post',
        data: data
    });
}
// 设置银行账户

export function setBankAccount(data) {
    return request({
        url: 'business/api/finance/setBankAccount',
        method: 'post',
        data: data
    });
}
