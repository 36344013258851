<template>
  <div class="bank-account">
    <div class="cell">
      <div class="title">我的支付服务</div>
      <div style="font-size: 14px; margin-top: 20px">
        您只能选择一种付款渠道
      </div>
      <div class="card-list" v-if="httpShow">
        <div
          class="card-item"
          @click="openTc(i)"
          v-for="(item, i) in bankList"
          :key="i"
        >
          <span class="btn" v-if="item.type == 1">
            <el-button @click.stop="toSimu(i)" size="small" plain
              >注册/登录</el-button
            >
          </span>
          <span class="btns" v-else>
            <div>账号</div>
            <div>{{ item.account }}</div>
            <div>收款人ID</div>
            <div>{{ item.collection_id }}</div>
          </span>
          <div class="whiteboard" v-if="item.type == 1"></div>
          <div v-else class="whiteboards"></div>
          <div class="round" v-if="item.type == 1">
            <img :src="item.timg" alt="" />
          </div>
          <div class="rounds" v-else>
            <img :src="item.dtimg" alt="" />
          </div>
          <div class="zt" v-if="item.status == 1 && item.type == 2">
            <div
              style="
                background: #fff;
                border-radius: 20px;
                margin-right: 10px;
                width: 18px;
                height: 17px;
              "
            >
              <i
                class="el-icon-success"
                style="color: #00bfa5; font-size: 18px"
              ></i>
            </div>
            活跃
          </div>
          <div class="zt" v-if="item.status == 0 && item.type == 2">
            <div
              style="
                background: #fff;
                border-radius: 20px;
                margin-right: 10px;
                width: 18px;
                height: 17px;
              "
            >
              <i
                class="el-icon-error"
                style="color: #9ea6ab; font-size: 18px"
              ></i>
            </div>
            不活跃
          </div>
          <img v-if="item.type == 1" :src="item.img" alt="" />
          <img v-else :src="item.dimg" alt="" />
          <!-- <div
            v-else
            style="
              background: #2e87eb;
              width: 100%;
              height: 100%;
              border-radius: 20px;
            "
          ></div> -->
        </div>
      </div>
      <div v-else style="text-align: center">
        <img :src="httpImg" alt="" />
      </div>
    </div>
    <el-dialog
      :title="bankList[index].name"
      :visible.sync="dialogVisible"
      width="500px"
      style="margin-top: 100px"
    >
      <div style="font-size: 16px">
        <div style="display: flex; align-items: center">
          <div style="width: 100px">账号：</div>
          <div style="color: #b0b0b0">{{ bankList[index].account }}</div>
        </div>
        <div style="display: flex; align-items: center; margin: 10px 0">
          <div style="width: 100px">收款人ID：</div>
          <div style="color: #b0b0b0">{{ bankList[index].collection_id }}</div>
        </div>
        <div style="display: flex; align-items: center">
          <div style="width: 100px">状态：</div>
          <div style="color: #b0b0b0">
            {{ bankList[index].status == 0 ? "不活跃" : "活跃" }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleUpdate">更新账户</el-button>
        <el-button @click="handleRemove">解除绑定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getBankAccountList, setBankAccount } from "@/api/shopIncome.js";
export default {
  data() {
    return {
      bankList: [
        {
          id: 0,
          img: require("@/assets/img/app/payoneer1.jpg"),
          timg: require("@/assets/img/app/payoneer2.jpg"),
          dimg: require("@/assets/img/app/payoneer3.jpg"),
          dtimg: require("@/assets/img/app/payoneer4.jpg"),
          name: "payoneer",
          index: 1,
          type: 1,
          status: 0,
          collection_id: 0,
          account: 0,
        },

        {
          id: 0,
          img: require("@/assets/img/app/ping.jpg"),
          timg: require("@/assets/img/app/ping2.jpg"),
          dimg: require("@/assets/img/app/ping3.jpg"),
          dtimg: require("@/assets/img/app/ping4.jpg"),
          name: "pingpong",
          index: 2,
          type: 1,
          status: 0,
          collection_id: 0,
          account: 0,
        },
        {
          id: 0,
          img: require("@/assets/img/app/lain.jpg"),
          timg: require("@/assets/img/app/lain2.jpg"),
          dimg: require("@/assets/img/app/lain3.jpg"),
          dtimg: require("@/assets/img/app/lain4.jpg"),
          name: "lianlian",
          index: 3,
          type: 1,
          status: 0,
          collection_id: 0,
          account: 0,
        },
      ],
      dialogVisible: false,

      index: 0,
      httpShow: false,
      httpImg: require("@/assets/img/app/http.gif"),
    };
  },
  created() {
    this.getList();
  },

  methods: {
    // 获取列表
    getList() {
      getBankAccountList().then((res) => {
        if (res.code == 1) {

          this.bankList.forEach((val) => {
            res.data.forEach((item) => {
              if (item.type == val.index) {
                val.type = 2;
                val.status = item.status;
                val.id = item.id;
                val.account =
                  item.account.substr(0, 3) + "****" + item.account.substr(7);
                val.collection_id = item.collection_id;
              }
            });
          });
          this.httpShow = true;
        }
      });
    },
    // 去登陆
    toSimu(index) {
      sessionStorage.BANKINDEX = this.bankList[index].index;
      this.$router.push("simuPayLogin");
    },
    // 打开弹窗
    openTc(index) {
      if (this.bankList[index].type != 1) {
        this.index = index;
        this.dialogVisible = true;
      }
    },
    handleOperation(type) {
      setBankAccount({
        status: type,
        id: this.bankList[this.index].id,
      }).then((res) => {
        if (res.code == 1) {
          this.dialogVisible = false;
          this.httpShow = false;
          this.getList();
          this.bankList[this.index].type = 1;
          this.bankList[this.index].status = 0;
          this.bankList[this.index].collection_id = 0;
          this.bankList[this.index].account = 0;
          this.$message({
            type: "success",
            message: type == 1 ? "更新成功!" : "解除绑定成功!",
          });
        }
      });
    },
    handleUpdate() {
      this.$confirm(
        "更新成功后，此账户将成为您的新收款账户；请注意，已结算的款项已经/仍将打入原收款账户",
        "更新账户",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.handleOperation(1);
        })
        .catch(() => {
          console.log("取消");
        });
    },
    handleRemove() {
      let data = this.bankList[this.index];
      this.$confirm(
        "您的付款账户" +
          data.account +
          "，与" +
          data.name +
          "解除绑定，您将无法使用付款服务，若重新绑定，将产生新的收款人id。若您想保留当前id，请不要操作绑定。",
        "与" + data.name + "解除绑定",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          this.handleOperation(2);
        })
        .catch(() => {
          console.log("取消");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.bank-account {
  // padding: 30px;
  .cell {
    padding: 30px;
    background: #fff;
    .title {
      font-size: 18px;
    }
    .card-list {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
      .card-item {
        // background: #b0b0b0;
        background-size: 100% 100%;
        height: 220px;
        width: 360px;
        border-radius: 20px;
        // display: flex;
        // justify-content: center;
        // padding-top: 160px;
        flex-shrink: 0;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        //  overflow:hidden;
        img {
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
        .btn {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          z-index: 1;
        }
        .btns {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          padding: 0 120px;
          font-size: 12px;
          line-height: 24px;
          z-index: 1;
        }
        .whiteboard {
          width: 100%;
          height: 70px;
          background: #f9f9f9;
          border-radius: 0 0 14px 14px;
          position: absolute;
          bottom: 0;
        }
        .whiteboards {
          width: 100%;
          height: 120px;
          background: #f5fafe;
          border-radius: 0 0 14px 14px;
          position: absolute;
          bottom: 0;
        }
        .round {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          background: #999;
          position: absolute;
          left: 30px;
          bottom: 40px;
          border: 2px solid #f9f9f9;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .rounds {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          background: #999;
          position: absolute;
          left: 30px;
          bottom: 80px;
          border: 2px solid #f9f9f9;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .zt {
          position: absolute;
          top: 70px;
          color: #fff;
          left: 120px;
          font-size: 14px;
          display: flex;
        }
      }
    }
  }
}
</style>